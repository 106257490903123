<template>

	<!-- Payment Methods Card -->
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{paddingTop: 0,}">
		<template #title>
			<a-row type="flex" align="middle">
				<a-col :span="24" :md="8">
					<h5 class="font-semibold m-0">Bank Info</h5>

					<h6 class="font-semibold m-0">{{ bankName }}</h6>
					<p class="text-dark">{{ bankAccountNumber }}</p>

				</a-col>
				<a-col :span="24" :md="8" class="mobile-add-bank align-items-center" style="display: flex; justify-content: flex-end">
				<a-button @click="handlePlaidLinkClick" type="primary">
					{{ addBankText }}
				</a-button>
				</a-col>
				<a-col :span="24" :md="8" class="align-items-center" style="display: flex; justify-content: flex-end">
				<a-button v-if="validBankAccout" @click="removeBankAccount" type="danger">
					Remove Bank Account
				</a-button>
				</a-col>
			</a-row>
		</template>
	</a-card>
	<!-- Payment Methods Card -->

</template>

<script>
import axios from 'axios';
import {create} from 'plaid';
import { mapActions } from 'vuex'
import { mapState } from 'vuex'

	export default ({
		data() {
			return {
				plaidLink: null,
		 		linkToken: null,
		 		plaidConfig: {
					 clientName: 'DOMOS',
					 env: 'sandbox',
					 product: ['auth', 'transactions'],
					 onSuccess: this.handlePlaidSuccess,
		 	 	},
				plaidHanler: null,
				addBankText:'Add Bank Account',
				bankName: 'Bank Name',
				bankAccountNumber: 'Account Number',
			}
		},
		async mounted() {
			await this.hasBankAccout();
			if(this.validBankAccout) {
				this.addBankText = 'Change Bank Account';
				let accountData = await this.getAccountData();
				this.bankName = accountData.name;
				this.bankAccountNumber = accountData.account_id;
				this.$emit('accountData', accountData);
			}

			this.initializePlaid();
		},
		methods: {
			async removeBankAccount() {
				await this.removeBankAccountAction();
				this.addBankText = 'Add Bank Account';
				this.bankName = 'Bank Name';
				this.bankAccountNumber = 'Account Number';
				this.$emit('validBankAccout', false);
				//refresh page
				location.reload();
				},
			handlePlaidLinkClick() {
	      this.plaidHanler.open();
	    },
			initializePlaid(){
					const script = document.createElement('script');
					script.src = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';
					script.onload = async () => {
						this.plaidHanler = window.Plaid.create({
							token: await this.getPlaidLinkToken(),
							onSuccess: async function(public_token, metadata) {
								const accountData = await this.exchangePublicToken({public_token})
								if(this.validBankAccout) {
									this.$emit('accountData', accountData);
									this.addBankText = 'Change Bank Account';
								}
							}.bind(this),
							onExit: function(err, metadata) {
								// The user exited the Link flow.
								if (err != null) {
									console.log("The user encountered a Plaid API error prior to exiting");
									// The user encountered a Plaid API error prior to exiting.
								}
							}
						});
					};
					document.body.appendChild(script);
			},
			...mapActions('building', ['getPlaidLinkToken', 'exchangePublicToken', 'hasBankAccout', 'getAccountData', 'removeBankAccountAction'])
	  },
		computed: {
			...mapState({
				validBankAccout: state => state.building.hasBankAccout
			})
		},


	})

</script>
<style scoped>
@media (max-width: 767px) {
  .header-solid .ant-col {
    margin-bottom: 10px; /* Change this value as needed */
  }
  .align-items-center {
    justify-content: flex-start !important;
  }
}
</style>